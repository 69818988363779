<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				<!-- <a-upload name="file" accept=".xlsx" :file-list="file" :remove="handleRemove" :before-upload="beforeUpload" class="ml7">
					<a-button class="btn btn-info"> <a-icon type="upload" /> Importar lista de precios </a-button>
				</a-upload>
				<div style="font-size: 12px;margin-top: 5px;">
					<a :href="layoutURL" target="_blank">Layout de ejemplo</a>
				</div> -->
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				Ancho <br />
				<a-select :allowClear="true" show-search style="width: 100%" v-model="selectedFilters.width">
					<a-select-option v-for="(element, index) in filters.width" :key="index" :value="element.value"> {{ element.value }} </a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				Perfil <br />
				<a-select :allowClear="true" show-search style="width: 100%" v-model="selectedFilters.side_face">
					<a-select-option v-for="(element, index) in filters.side_face" :key="index" :value="element.value"> {{ element.value }} </a-select-option>
				</a-select>
			</div>
			<div class="col-md-4">
				Rin <br />
				<a-select :allowClear="true" show-search style="width: 100%" v-model="selectedFilters.rin">
					<a-select-option v-for="(element, index) in filters.rin" :key="index" :value="element.value"> {{ element.value }} </a-select-option>
				</a-select>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<a-table
					:columns="columns"
					:dataSource="filteredList"
					rowKey="key"
					:pagination="false"
					class="ant-table-striped pt10"
					:rowClassName="(_record, index) => (index % 2 === 1 ? 'table-striped' : '')"
				>
					<div slot="label" slot-scope="record">
						<span @click="copyText(record.id)">
							{{ record.label }}
						</span>
					</div>
					<div slot="prices" slot-scope="record">
						{{ numeral(record.price).format('$0,0.00') }}
					</div>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import copy from 'copy-to-clipboard'
import Swal from 'sweetalert2'

export default {
	name: 'tireQuoterView',
	computed: {
		...mapGetters('tireQuoter', ['list', 'spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			filteredList: [],
			filters: {
				width: [],
				side_face: [],
				rin: [],
			},
			selectedFilters: {
				width: '',
				side_face: '',
				rin: '',
			},
			columns: [
				{
					title: 'Descripción',
					scopedSlots: { customRender: 'label' },
				},
				{
					title: 'Ancho',
					dataIndex: 'width',
					key: 'width',
				},
				{
					title: 'Perfil',
					dataIndex: 'side_face',
					key: 'side_face',
				},
				{
					title: 'Rin',
					dataIndex: 'rin',
					key: 'rin',
				},
				{
					title: 'Stock',
					dataIndex: 'stock',
					key: 'stock',
				},
				{
					title: 'Precio',
					scopedSlots: { customRender: 'prices' },
				},
			],
			file: [],
			layoutURL: `${process.env.VUE_APP_API_URL}/public/files/example-tireImporter.xlsx`,
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		handleRemove() {
			this.file = []
		},
		beforeUpload(file) {
			this.file = [file]
			return false
		},
		initModule() {},
		copyText(id) {
			let price = this.filteredList.find((e) => e.id == id).price
			if (!price) {
				Swal.fire({
					title: 'Atención',
					text: 'El elemento seleccionado no cuenta con precio. Debe solicitarse al proveedor de manera directa',
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#41b883',
					confirmButtonText: 'Continuar',
				}).then(() => {
					copy('')
				})
			} else {
				copy(`${this.filteredList.find((e) => e.id == id).label} - ${numeral(price).format('$0,0.00')}`)
				Vue.prototype.$notification.success({
					message: 'Catálogo de llantas',
					description: 'Mensaje copiado',
				})
			}
		},
	},
	watch: {
		list(newValue) {
			newValue.forEach((element) => {
				if (!this.filters.width.find((e) => e.value == element.width)) {
					this.filters.width.push({
						value: element.width,
					})
				}
				if (!this.filters.side_face.find((e) => e.value == element.side_face)) {
					this.filters.side_face.push({
						value: element.side_face,
					})
				}
				if (!this.filters.rin.find((e) => e.value == element.rin)) {
					this.filters.rin.push({
						value: element.rin,
					})
				}
			})
		},
		selectedFilters: {
			deep: true,
			handler(newValue) {
				this.filteredList = []
				let localFilteredList = []

				if (utilities.objectValidate(newValue, 'width', false) && newValue.width != '') {
					this.filteredList = this.list.filter((e) => e.width == newValue.width)
				}
				if (utilities.objectValidate(newValue, 'side_face', false) && newValue.side_face != '') {
					this.filteredList = this.filteredList.filter((e) => e.side_face == newValue.side_face)
				}
				if (utilities.objectValidate(newValue, 'rin', false) && newValue.rin != '') {
					this.filteredList = this.filteredList.filter((e) => e.rin == newValue.rin)
				}
				this.filteredList.forEach((e) => {
					if (e.stock > 0) {
						let dataPrice = numeral(JSON.parse(e.prices).sale_price).value()
						localFilteredList.push({
							...e,
							price: dataPrice,
						})
					}
				})
				localFilteredList.sort((a, b) => {
					if (a.stock > b.stock) return -1
					if (a.stock < b.stock) return 1
					return 0
				})

				this.filteredList = _.cloneDeep(localFilteredList)
			},
		},
	},
}
</script>