<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-8">
					<a-radio-group :value="filters.status" @change="handleStatusChange" style="padding: 0">
						<a-radio-button value="0"> Autorizadas </a-radio-button>
						<a-radio-button value="1"> En preparación </a-radio-button>
						<a-radio-button value="2"> En reparto </a-radio-button>
						<a-radio-button value="3"> Entregado </a-radio-button>
					</a-radio-group>
				</div>
				<div class="col-md-4 text-right">
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="ordersList" rowKey="id" bordered>
						<div slot="fullName" slot-scope="record" class="text-left">
							{{ record.fullName }}
							<div>{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
						</div>
						<div slot="customer_name" slot-scope="record" class="text-left">
							{{ record.customer_name }}
						</div>
						<div slot="description" slot-scope="record" class="text-left">
							<div v-for="(element, index) in record.orderElements" :key="index">{{ `${element.quantity}` }}x {{ element.description }}</div>
						</div>
						<div slot="action" slot-scope="record">
							<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="changeStatus(record.id, record.status)" v-if="[0, 1, 2].includes(record.status)">
								<a-icon type="check" />
							</button>
							<div v-if="!record.pos_id">
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="setTicket(record.id, record.status - 1)" v-if="[0, 1, 2].includes(record.status)">
									<a-icon type="file-text" />
								</button>
							</div>
							<div v-else>
								<small>Ticket #{{ record.pos_id }}</small>
							</div>
							<div class="pointer" @click="downloadQuotation(record.id)">ver pedido</div>
							<div class="pointer pt5" v-if="record.status == 2 && record.order_id">
								<b>Cotización #{{ record.order_id }}</b>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalQuotationView" title="Detalle del Pedido" @cancel="closeQuotationModal" :closable="true" width="80%" v-if="modalQuotationView">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<object :data="quotationBase64" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'

export default {
	name: 'OrdersView',
	computed: {
		...mapGetters('orders', ['spinnerLoader', 'spinnerLoaderLabel', 'ordersList', 'filters']),
		...mapGetters('user', ['user']),
	},
	data() {
		return {
			searchText: '',
			modalQuotationView: false,
			quotationBase64: '',
			columns: [
				{
					title: '#',
					dataIndex: 'impressment_id',
					key: 'impressment_id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'fullName' },
					width: '15%',
				},
				{
					title: 'Cliente/Distribuidor',
					scopedSlots: { customRender: 'customer_name' },
					width: '15%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'description' },
					align: 'center',
					width: '50%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: '10%',
				},
			],
			columnsElements: [
				{
					title: '#',
					align: 'center',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'SKU - Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'price' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'importe' },
				},
			],
			actualRecord: {},
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		handleStatusChange(e) {
			this.$store.commit('orders/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		initModule() {
			this.$store.dispatch('orders/GET', {
				status: this.filters.status,
			})
		},
		downloadQuotation(id) {
			this.$store.dispatch('orders/GENERATE_PDF', id).then((response) => {
				this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
				setTimeout(() => {
					this.modalQuotationView = true
				}, 100)
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
		setTicket(id, status) {
			// Solicitamos la terminación del ticket de pago
			Swal.fire({
				title: 'Pedidos',
				text: 'Ingresa los últimos dígitos del ticket de pago',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#41b883',
				cancelButtonColor: '#f5222e',
				reverseButtons: true,
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value || isNaN(value)) {
							resolve('Ingresa los últimos dígitos del ticket de pago')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.changeStatus(id, status, result.value)
					// console.log('result-->', result)
				}
			})
		},
		async changeStatus(id, status, posID = false) {
			//
			this.actualRecord = _.cloneDeep(this.ordersList.find((e) => e.id == id))
			let customerAddressID = false

			if (status == 1) {
				// Obtenemos direcciones de entrega dadas de alta por el cliente
				let addresses = await this.$store
					.dispatch('orders/GET_ADDRESSES', {
						customer_id: this.actualRecord.customer_id,
					})
					.then((responseAddresses) => {
						// console.log('responseAddresses-->', responseAddresses)
						return responseAddresses.data
					})

				if (!addresses.length) {
					Swal.fire({
						title: 'Atención',
						text: 'El cliente no ha dado de alta una dirección de entrega',
						icon: 'error',
					})
					return false
				}

				// Mostramos seleccionador de dirección de envío
				let selectAddresses = {
					'-1': '🕋 En sucursal',
				}
				addresses.map((element) => {
					selectAddresses[element.id] = element.description.toUpperCase()
				})

				customerAddressID = await Swal.fire({
					icon: 'info',
					title: `Selecciona una dirección de entrega`,
					input: 'select',
					showCancelButton: true,
					reverseButtons: true,
					confirmButtonColor: '#41b883',
					confirmButtonText: 'Continuar',
					cancelButtonColor: '#f5222e',
					cancelButtonText: 'Cancelar',
					inputOptions: {
						...selectAddresses,
					},
					inputPlaceholder: 'Selecciona una opción',
					showCancelButton: true,
					inputValidator: (value) => {
						return new Promise((resolve) => {
							if (value) {
								resolve()
							} else {
								resolve('Es necesario seleccionar una opción')
							}
						})
					},
				}).then((result) => {
					if (result.isConfirmed) {
						return result.value
					}
				})

				if (!customerAddressID) {
					return false
				}
			}

			Swal.fire({
				title: 'Atención',
				text: '¿Deseas actualizar el estatus del pedido?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Se actualiza pedido
					await this.$store.dispatch('orders/UPDATE_STATUS', {
						id: id,
						status: status + 1,
						customerAddressID,
						posID,
					})

					this.initModule()
					this.actualRecord = {}
				}
			})
		},
	},
}
</script>

<style lang="scss">
.tableImpressmentElements {
	td {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}
</style>