<template>
	<a-spin :tip="spinnerLoaderLabel" :spinning="spinnerLoader">
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-8">
					<a-form-item label="Nombre comercial del cliente">
						<a-input
							v-decorator="[
								'full_name',
								{
									rules: requiredFileds,
								},
							]"
							autocomplete="off"
						/>
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Tipo de Cliente">
						<a-select
							style="width: 100%"
							v-decorator="[
								'customer_type',
								{
									rules: requiredFileds,
								},
							]"
						>
							<a-select-option value="general">Público General</a-select-option>
							<a-select-option value="supplier">Distribuidor</a-select-option>
							<a-select-option value="maquila">Maquila</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Persona de Contacto">
						<a-input v-decorator="['contact_name', { rules: emailField }]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Puesto">
						<a-input v-decorator="['position', { rules: emailField }]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Email">
						<a-input v-decorator="['email', { rules: emailField }]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Teléfono">
						<a-input v-decorator="['phone']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-12">
					<a-form-item label="Comentarios/Observaciones">
						<a-textarea v-decorator="['comments']" />
					</a-form-item>
				</div>
			</div>
			<div class="row" v-show="showActionButtons">
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Cancelar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" id="customersPartialsGeneralSubmit" htmlType="submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import copy from 'copy-to-clipboard'

export default {
	name: 'customerGeneralComponent',
	props: {
		returnData: {
			type: Boolean,
			default: false,
		},
		showActionButtons: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters('customers', ['actualRecord', 'spinnerLoaderLabel', 'spinnerLoader']),
		isNewRecord() {
			if (this.returnData) {
				return true
			} else {
				return this.$route.params.id == 'new' ? true : false
			}
		},
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
		emailField() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
				{
					email: true,
					message: this.$t('general.validEmail'),
				},
			]
			return rules
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			permalink: '',
		}
	},
	methods: {
		onCancel() {
			this.$router.replace('/customers')
		},
		onCopyLink() {
			copy(this.permalink)
		},
		async setLocalData(newValue) {
			if (newValue) {
				this.form.setFieldsValue({
					contact_name: utilities.objectValidate(newValue, 'general.contact_name', ''),
					position: utilities.objectValidate(newValue, 'general.position', ''),
					customer_type: utilities.objectValidate(newValue, 'general.customer_type', ''),
					full_name: utilities.objectValidate(newValue, 'general.full_name', ''),
					email: utilities.objectValidate(newValue, 'general.email', ''),
					phone: utilities.objectValidate(newValue, 'general.phone', ''),
					comments: utilities.objectValidate(newValue, 'general.comments', ''),
				})
			}
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
						section: 'customers',
					}
					if (this.isNewRecord) {
						this.$store
							.dispatch('customers/CREATE', {
								payload,
								returnData: this.returnData,
							})
							.then((response) => {
								if (this.returnData) {
									this.$emit('close', response.data)
								}
							})
					} else {
						payload.customer_id = this.actualRecord.general.id
						this.$store.dispatch('customers/UPDATE', payload)
					}
				}
			})
		},
		cleanFormData() {
			this.form.resetFields()
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'general.id', false)) {
					setTimeout(() => {
						this.setLocalData(newValue)
					}, 100)
				}
			},
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>