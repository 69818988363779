<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<div class="row">
				<div :class="[showAddButton ? 'col-md-10' : 'col-md-12']">
					<a-input-search v-model="searchText" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" :allowClear="true" />
				</div>
				<div class="col-md-2 text-right" v-if="showAddButton">
					<a-button class="btn btn-info btn-block" icon="plus" @click="onAddRecord">Añadir cliente</a-button>
				</div>
				<div class="col-md-12 pt10" v-if="customersList.length > 0">
					<a-table :columns="columns" :dataSource="customersList" :row-selection="rowSelection" rowKey="key" :pagination="false">
						<div slot="customer_type" slot-scope="record">
							{{ record.customer_type == 'supplier' ? 'Distribuidor' : 'Público General' }}
						</div>
						<div slot="phones" slot-scope="record">
							{{ record.phone }}
						</div>
					</a-table>
				</div>
			</div>
			<div class="row pt10" v-if="showAddButtonAfterNullSearch && searchedClient">
				<div class="col-md-12">
					<hr class="hrDotted" />
				</div>
				<div class="col-md-4 offset-md-4 text-center">
					<a-button class="btn btn-info btn-block mt10" icon="plus" @click="onAddRecord">Añadir cliente</a-button>
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import { clientTypes } from '@/constants'
import Swal from 'sweetalert2'

export default {
	name: 'clientsQuickSearchComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		showAddButton: {
			type: Boolean,
			default: false,
		},
		showAddButtonAfterNullSearch: {
			type: Boolean,
			default: false,
		},
		searchType: {
			default: false,
		},
	},
	computed: {
		...mapGetters('customers', ['customersList', 'spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			clientTypes,
			selectedRecord: {},
			searchText: '',
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre del cliente',
					dataIndex: 'full_name',
					key: 'full_name',
				},
				{
					title: 'Tipo de cliente',
					scopedSlots: { customRender: 'customer_type' },
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: 'Teléfono',
					scopedSlots: { customRender: 'phones' },
				},
			],
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRecord = _.cloneDeep(selectedRows[0])
				},
				type: 'radio',
			},
			searchedClient: false,
		}
	},
	methods: {
		onSearch() {
			this.$store.dispatch('customers/SEARCH', { searchText: this.searchText })
			this.searchedClient = true
		},
		onSelectedRecord() {
			if (utilities.objectValidate(this.selectedRecord, 'id', false)) {
				this.$emit('selectedRecord', this.selectedRecord)
			} else {
				Swal.fire({
					title: 'Catálogo de Clientes',
					text: 'Debes seleccionar un registro para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onAddRecord() {
			this.$emit('addClient')
		},
	},
	watch: {
		visible() {
			this.$store.commit('customers/SET_STATE', {
				customersList: [],
			})
			this.searchText = ''
			this.searchedClient = false
		},
	},
}
</script>