<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Añadir</a-button>
					<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
				</div>
			</div>
			<div class="row pt15">
				<div class="col-md-12">
					<a-input allowClear v-model="searchText" @change="onSearch" placeholder="Búsqueda en clientes..." />
				</div>
				<div class="col-md-12 pt10">
					<a-table :columns="columns" :dataSource="searchText == '' ? customersList : customersListFiltered" :pagination="{ pageSize: 25 }">
						<div slot="customer_type" slot-scope="record">
							{{ clientTypes[record.customer_type] }}
						</div>
						<div slot="phones" slot-scope="record">
							{{ record.phone }}
						</div>
						<div slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
							<a-button class="ml7" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="toggleRecord(record.id)" />
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { clientTypes } from '@/constants'

export default {
	name: 'customersView',
	data() {
		return {
			clientTypes,
			searchText: '',
			customersListFiltered: [],
		}
	},
	computed: {
		...mapGetters('customers', ['customersList', 'spinnerLoader', 'spinnerLoaderLabel']),
		columns() {
			let columns = [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre del cliente',
					dataIndex: 'full_name',
					key: 'full_name',
				},
				{
					title: 'Tipo de cliente',
					scopedSlots: { customRender: 'customer_type' },
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: 'Teléfono',
					scopedSlots: { customRender: 'phones' },
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '10%',
				},
			]
			return columns
		},
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			let payload = {}
			this.$store.dispatch('customers/GET', payload)
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/customers/new')
		},
		onEditRecord(id) {
			this.$store.dispatch('customers/GET_ONE', id).then(() => {
				this.$router.push(`/customers/${id}`)
			})
		},
		toggleRecord(id) {
			Swal.fire({
				title: 'Atención',
				html: '¿Desea eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('customers/TOGGLE', {
						section: 'customers',
						id,
					})
				}
			})
		},
		onSearch(event) {
			let { value } = event.target
			this.customersListFiltered = []
			if (value != '') {
				this.customersListFiltered = this.customersList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value.toLowerCase())) {
							return true
						}
					}),
				)
			}
		},
	},
}
</script>