<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoaderProduct">
			<div v-if="isEditable">
				<div class="row">
					<div class="mt20" :class="customerID == 35 ? 'col-md-3' : 'col-md-4'">
						<a-input-search v-model="searchText" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
					</div>
					<div class="col-md-3" :class="customerID == 35 ? 'col-md-3' : 'col-md-4'">
						<b>Distribuidor</b>
						<a-select
							v-model="customerID"
							:style="{ width: '100%' }"
							:allowClear="true"
							show-search
							option-filter-prop="children"
							:filter-option="filterOption"
							placeholder="Cliente Distribuidor"
							@change="onChangeCustomer"
						>
							<a-select-option v-for="(element, index) in customersList" :key="index" :value="element.id"> {{ element.full_name }} </a-select-option>
						</a-select>
					</div>
					<div class="col-md-4" v-if="hasUnavailableProducts">
						<b>Fecha de entrega</b>
						<a-date-picker v-model="deliveryDate" :value-format="'YYYY-MM-DD'" :format="'DD-MM-YYYY'" placeholder="Selecciona la fecha" style="width: 100%" :disabled="!isEditable" />
					</div>
					<div class="col-md-12">
						<hr />
					</div>
				</div>
			</div>
			<div v-if="isEditable">
				<div v-if="cart.length">
					<div class="row pt20" v-for="(element, index) in cart" :key="index">
						<div class="col-12" v-if="index > 0">
							<hr class="hrDotted" />
						</div>
						<div class="col-md-1 text-center">
							<div style="font-size: 50px; text-align: center; height: 50px; vertical-align: middle; line-height: 60px">
								{{ index + 1 }}
							</div>
						</div>
						<div class="col-md-11">
							<div class="row">
								<div class="col-md-3">
									<b>Cantidad</b>
									<a-input v-mask="'####'" v-model="element.quantity" :disabled="!isEditable" />
								</div>
								<div class="col-md-3">
									<b>Precio unitario</b>
									<a-input autocomplete="off" v-model="element.sale_price" prefix="$" v-money="money" :disabled="!isEditable" />
								</div>
								<div class="col-md-6">
									<b>SKU - Descripción</b>
									<div>
										<b>{{ element?.sku || '' }}</b> - {{ element?.product?.label || element.description }}
									</div>
									<div>
										<a-checkbox :checked="!!element?.available" @change="onChangeAvailability(index)"> Disponibilidad inmediata </a-checkbox>
									</div>
								</div>
							</div>
							<div class="row pt5" v-if="isEditable">
								<div class="col-md-12 text-right" v-if="cart.length > 1">
									<button class="btn btn-danger btn-sm" @click="removeElement(index)">Eliminar partida</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="cart" rowKey="id" :pagination="false">
						<div slot="index" slot-scope="record">
							{{ record.index }}
						</div>
						<div slot="quantity" slot-scope="record">
							<div class="text-center">{{ record.quantity }}</div>
						</div>
						<div slot="description" slot-scope="record">
							<div class="text-left">{{ record?.sku || '' }} - {{ record?.product?.label || record.description }}</div>
						</div>
						<div slot="price" slot-scope="record">
							{{ numeral(record.sale_price).format('$0,0.00') }}
						</div>
						<div slot="importe" slot-scope="record">
							{{ numeral(numeral(record.quantity).value() * numeral(record.sale_price).value()).format('$0,0.00') }}
						</div>
					</a-table>
				</div>
			</div>
			<div class="row pt20">
				<div class="col-12" v-if="elements.length == 1">
					<hr class="hrDotted" />
				</div>
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success ml7" @click="onSave" v-if="isEditable">Guardar</a-button>
					<a-button icon="file" class="btn btn-warning ml7" @click="onCloseQuotation" v-if="!isNewRecord && isEditable && allowCloseQuotation">Cerrar cotización</a-button>
					<a-button icon="file" class="btn btn-info" @click="getQuotation" v-if="actualImpressment.status > 0">ver cotización</a-button>
				</div>
			</div>
		</a-spin>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.products.visible" title="Listado de Productos" :closable="true" @cancel="onCloseModal('products')" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('products')"> Cerrar </a-button>
			</template>
			<productsListComponent @selectedProduct="onSelectProduct" />
		</a-modal>
		<!-- Búsqueda de cliente -->
		<a-modal :visible="modal.client.visible" title="Búsqueda de Cliente" :closable="false" width="60%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('client')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="addClient"> Seleccionar </a-button>
			</template>
			<clientsQuickSearchComponent ref="clientsQuickSearchComponent" :visible="modal.client.visible" :showAddButton="false" @selectedRecord="onSelectedClient" @addClient="onAddClient" />
		</a-modal>
		<a-modal :visible="modalQuotationView" title="Detalle de cotización" :closable="false" width="80%" v-if="modalQuotationView">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<object :data="quotationBase64" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { clientModel } from '@/constants'
import utilities from '@/services/utilities'
import { impressment } from '@/constants'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import productsListComponent from '../productsList'
import clientsQuickSearchComponent from '@/components/clients/quickSearch'

export default {
	name: 'insertOrUpdateImpressment',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		productsListComponent,
		clientsQuickSearchComponent,
	},
	computed: {
		...mapGetters('products', ['productsList', 'spinnerLoaderLabel']),
		...mapGetters('impressment', ['actualImpressment']),
		...mapGetters('customers', ['customersList']),
		hasUnavailableProducts() {
			return !!this.cart.find((e) => !e.available)
		},
		isNewRecord() {
			return !!!utilities.objectValidate(this.$route, 'params.id', false)
		},
		isEditable() {
			return this.isNewRecord || this.actualImpressment.status == 0
		},
		allowCloseQuotation() {
			return !this.elements.filter((e) => e.available && numeral(e.price).value() == 0).length
		},
		spinnerLoaderProduct() {
			return this.$store.state.products.spinnerLoader
		},
	},
	data() {
		return {
			deliveryDate: '',
			searchText: '',
			elements: [],
			trengo: '',
			customerID: '',
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			columns: [
				{
					title: '#',
					align: 'center',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'SKU - Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'price' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'importe' },
				},
			],
			modal: {
				products: {
					visible: false,
				},
				client: {
					visible: false,
				},
			},
			client: _.cloneDeep(clientModel),
			cart: [],
			modalQuotationView: false,
			quotationBase64: '',
		}
	},
	beforeMount() {
		this.$store.dispatch('customers/GET')
	},
	mounted() {
		if (this.isNewRecord) {
			this.elements.push(_.cloneDeep(impressment))
		} else {
			if (utilities.objectValidate(this.actualImpressment, 'elements', false)) {
				this.elements = _.cloneDeep(this.actualImpressment.elements)
			} else {
				this.$store.dispatch('impressment/GET_ONE', this.$route.params.id)
			}
		}
	},
	methods: {
		numeral,
		onChangeCustomer(customerID) {
			// identificamos si es publico en general
			if (customerID == 35) {
				console.log('onChangeCustomer-->', customerID)
			}
		},
		onChangeAvailability(index) {
			// console.log('onChangeAvailability-->', index)
			this.cart[index].available = !this.cart[index].available
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		removeElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar el elemento <b>"${this.cart[index].product?.label || this.cart[index].description}"</b>?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let allElements = _.cloneDeep(this.cart)
					this.cart = []
					allElements.splice(index, 1)
					this.cart = _.cloneDeep(allElements)
				}
			})
		},
		async onCloseModal(section) {
			switch (section) {
				case 'products':
					this.selectedProduct = {}
					this.modal[section].visible = false
					break
				case 'addClient':
					this.modal[section].visible = false
					break
				case 'client':
					this.modal[section].visible = false
					this.customer_type = 'general'
					break

				default:
					this.modal[section].visible = false
					break
			}
		},
		onShowModal(section) {
			this.modal[section].visible = true
		},
		onAddClient() {
			// this.onCloseModal('client')
			this.onShowModal('addClient')
		},
		onSearch() {
			if (this.searchText == '' || this.searchText.length < 3) {
				Swal.fire({
					title: 'Cotizaciones',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.searchText, fromPOS: false })
			}
		},
		onSelectProduct(product) {
			// console.log('onSelectProduct-->', product)
			// return false
			let quantity = utilities.objectValidate(product, 'quantity', 1)
			let index = this.cart.findIndex((e) => e.sku == product.sku)

			if (index == -1) {
				this.cart.unshift({
					product,
					label: `${quantity} x ${product.label.toUpperCase()}`,
					sale_price: numeral(0).format('0,0.00'),
					sku: product.sku,
					quantity,
					available: false,
				})
			} else {
				let allCart = _.cloneDeep(this.cart)
				this.cart = []
				let cart = _.cloneDeep(allCart[index])
				quantity = cart.quantity + 1
				amount = cart.sale_price * quantity

				cart = {
					...cart,
					label: `${quantity} x ${product.label.toUpperCase()}`,
					sale_price: '',
					quantity,
					available: false,
				}

				allCart[index] = _.cloneDeep(cart)
				this.cart = allCart
			}
			this.searchText = ''
			this.onCloseModal('products')
		},
		addClient() {
			//
			this.$refs.clientsQuickSearchComponent.onSelectedRecord()
		},
		onSelectedClient(payload) {
			// console.log('onSelectedClient-->', payload);
			this.client = _.cloneDeep(payload)
			this.onCloseModal('client')
			this.onCloseModal('addClient')
		},
		async onSave() {
			let errors = []
			this.cart.forEach((element, index) => {
				let item = index + 1
				if (element.quantity == '') {
					errors.push(`- Se requiere cantidad en la partida #${item}`)
				}
				//
				if (numeral(element.sale_price).value() == 0) {
					errors.push(`- Se requiere precio en la partida #${item}`)
				}
			})

			// validamos si se establece un distribuidor
			if (this.customerID == '') {
				errors.push(`- Selecciona distribuidor`)
			}

			// validamos si existe disponibilidad de todos los productos
			if (this.hasUnavailableProducts && this.deliveryDate == '') {
				errors.push(`- Cuentas con al menos un producto sin disponibilidad inmediata. Debes ingresar fecha de entrega`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				text: '¿Confirmas realizar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let payload = {
						elements: this.cart,
						customerID: this.customerID,
						deliveryDate: this.deliveryDate,
						hasUnavailableProducts: this.hasUnavailableProducts,
					}

					if (this.isNewRecord) {
						await this.$store
							.dispatch('impressment/CREATE', payload)
							.then(async (response) => {
								await Swal.fire({
									title: 'Cotizaciones',
									text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
									icon: 'success',
									confirmButtonText: 'Ok',
								})
							})
							.catch(async (error) => {
								await Swal.fire({
									title: 'Cotizaciones',
									text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
									icon: 'error',
									confirmButtonText: 'Ok',
								})
							})
					} else {
						payload = {
							...payload,
							id: this.$route.params.id,
						}
						await this.$store
							.dispatch('impressment/UPDATE', payload)
							.then(async (response) => {
								await Swal.fire({
									title: 'Cotizaciones',
									text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
									icon: 'success',
									confirmButtonText: 'Ok',
								})
							})
							.catch(async (error) => {
								await Swal.fire({
									title: 'Cotizaciones',
									text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
									icon: 'error',
									confirmButtonText: 'Ok',
								})
							})
					}
				}
			})
		},
		onCancel() {
			this.$router.replace('/impressment')
		},
		onCloseQuotation() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cerrar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Guardamos la información actual
					await this.onSave()

					await this.$store
						.dispatch('impressment/UPDATE_STATUS', {
							id: this.$route.params.id,
							status: 1, // Cotizadas
						})
						.then(async (response) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
								icon: 'success',
								confirmButtonText: 'Ok',
							})
						})
						.catch(async (error) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
								icon: 'error',
								confirmButtonText: 'Ok',
							})
						})

					// console.log('ENVIAR PDF', { trengo: this.trengo })
					// if (this.trengo != '') {
					// 	Swal.fire({
					// 		title: 'Atención',
					// 		text: '¿Deseas enviar la cotización al cliente?',
					// 		icon: 'warning',
					// 		showCancelButton: true,
					// 		reverseButtons: true,
					// 		confirmButtonColor: '#41b883',
					// 		confirmButtonText: 'Sí, continuar',
					// 		cancelButtonColor: '#f5222e',
					// 		cancelButtonText: 'No, cancelar',
					// 	}).then(async (resultNotification) => {
					// 		if (resultNotification.isConfirmed) {
					// 			//enviamos cotización por trengo
					// 			if (utilities.objectValidate(this.actualImpressment, 'trengo', false)) {
					// 				let payload = {
					// 					id: this.actualImpressment.id,
					// 					ticket: this.actualImpressment.trengo.toLowerCase().split('tickets/')[1],
					// 				}
					// 				await this.$store.dispatch('impressment/SEND_GENERATE_PDF', payload)
					// 			}
					// 		}
					// 	})
					// }
				}
			})
		},
		getQuotation() {
			this.$store.dispatch('impressment/GENERATE_PDF', this.$route.params.id).then((response) => {
				this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
				setTimeout(() => {
					this.modalQuotationView = true
				}, 100)
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
	},
	watch: {
		productsList: {
			deep: true,
			handler(newData) {
				// console.log('newData-->', newData)
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				}
				if (newData.length > 1) {
					this.modal.products.visible = true
				}
			},
		},
		actualImpressment(newData) {
			if (utilities.objectValidate(newData, 'impressmentElements', false)) {
				// console.log('watch::actualImpressment::newData-->', newData)
				this.customerID = utilities.objectValidate(newData, 'customer_id', '')
				this.deliveryDate = utilities.objectValidate(newData, 'delivery_date', '')
				this.cart = newData.impressmentElements.map((e, index) => {
					return {
						...e,
						sale_price: numeral(e.price).format('0,0.00'),
						index: index + 1,
					}
				})
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>